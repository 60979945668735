<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    :max-width="dialogWidth"
    scrollable
    fullscreen
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar>
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ $t('app.buttons.edit') }}
            Calentamiento
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            rounded
            color="primary"
            @click="saveItem"
          >
            {{ $t('app.buttons.save') }}
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <validation-observer
          ref="observer"
          v-slot="{ validate, reset }"
        >
          <form>
            <v-list
              three-line
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    Descripción
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Descripción"
                      rules="required"
                    >
                      <tiptap-vuetify
                        v-model="item.overview"
                        :extensions="extensions"
                        />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-row>
                <v-col>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Repeticiones
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Repeticiones"
                          rules="required"
                        >
                          <v-text-field
                            v-model="item.replays"
                            type="number"
                            placeholder="Repeticiones"
                          />
                        </validation-provider>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Duración
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Duración"
                          rules=""
                        >
                          <v-text-field
                            v-model="item.duration"
                            placeholder="Duración"
                          />
                        </validation-provider>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Intensidad
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Intensidad"
                          rules=""
                        >
                          <v-text-field
                            v-model="item.intensity"
                            placeholder="Intensidad"
                          />
                        </validation-provider>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Intervalo de descanso
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Intervalo de descanso"
                          rules=""
                        >
                          <v-text-field
                            v-model="item.rest_interval"
                            placeholder="Intervalo de descanso"
                          />
                        </validation-provider>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-list>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import customFilter from '@/plugins/customFilter'
  import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'

  export default {
    name: 'MainSeriesEdit',
    mixins: [customFilter],
    components: {
      TiptapVuetify,
    },
    data () {
      return {
        extensions: [
          History,
          Blockquote,
          Link,
          Underline,
          Strike,
          Italic,
          ListItem,
          BulletList,
          OrderedList,
          [Heading, {
            options: {
              levels: [1, 2, 3]
            }
          }],
          Bold,
          Code,
          HorizontalRule,
          Paragraph,
          HardBreak
        ],
        dialog: false,
        item: {
          overview: "",
          intensity: "",
          rest_interval: "",
          replays: "",
          duration: ""
        },
        planes: [],
        metas: [],
        usuarios: [],
        weekModel: {
          days: [],
          name: ""
        },
      }
    },
    mounted () {
      EventBus.$on('warm-ups-edit',  (item) => {
        this.item   = item
        this.dialog = true
      })
    },
    methods: {
      async saveItem () {
        this.$refs.observer.validate()
          .then(async success => {
            if (!success) return

            this.toggleLoader()

            await this.$http.put(route(`warm-ups/${this.item.id}`), {
              data: this.item
            })
              .then(response => {
                if (response.body.data) {
                  //this.processSuccess(response)
                  this.dialog = false
                  EventBus.$emit('reload-items-warm-ups-edit', this.item)
                } else {
                  this.processError(response)
                }
              }, error => {
                this.processError(error)
              })

            this.toggleLoader()
          })
      },
    },
  }
</script>

<style scoped>

</style>
